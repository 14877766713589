import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/Layout.vue"

Vue.use(VueRouter)

const routes = [{
		path: '/',
		component: Layout,
		children: [
			{
				path: '/login',
				name: 'login',
				component: () => import('@/views/login/index.vue'),
			},
			{
				path: '/redirect',
				name: 'redirect',
				component: () => import('@/views/login/redirect.vue'),
			},
			{
				path: '/base',
				name: 'base',
				component: () => import('@/views/home/base.vue'),
				children: [{
						path: 'home',
						name: 'home',
						component: () => import('@/views/home/home.vue'),
					},
					{
						path: 'appList',
						name: 'appList',
						component: () => import('@/views/home/appList.vue'),
					},
					{
						path: 'user',
						name: 'user',
						component: () => import('@/views/home/user.vue'),
					}
				]
			},
			{
				path: '/goods',
				name: 'goods',
				component: () => import('@/views/goods/good-list.vue'),
				children: [{
					path: 'good-list',
					name: 'good-list',
					component: () => import('@/views/goods/good-list.vue'),
				}]
			},
			{
				path: '/goods',
				name: 'goods',
				component: () => import('@/views/goods/good-share.vue'),
				children: [{
					path: 'good-share',
					name: 'good-share',
					component: () => import('@/views/goods/good-share.vue'),
				}]
			},
			{
				path: '/mp-assistant',
				name: 'mp-assistant',
				component: () => import('@/views/mp-assistant/app/app.vue'),
				children: [{
					path: 'app',
					name: 'app',
					component: () => import('@/views/mp-assistant/app/app.vue'),
				}]
			},
			{
				path: '/mp-assistant',
				name: 'mp-assistant',
				component: () => import('@/views/mp-assistant/mp/mp'),
				children: [{
					path: 'mp',
					name: 'mp',
					component: () => import('@/views/mp-assistant/mp/mp.vue'),
				}]
			},
			{
				path: '/mp-assistant',
				name: 'mp-assistant',
				component: () => import('@/views/mp-assistant/month-saleinfo/index.vue'),
				children: [{
					path: 'month-saleinfo',
					name: 'month-saleinfo',
					component: () => import('@/views/mp-assistant/month-saleinfo/index.vue'),
				}]
			},
			{
				path: '/mp-assistant',
				name: 'mp-assistant',
				component: () => import('@/views/mp-assistant/month-saleinfo/product.vue'),
				children: [ {
					path: 'product',
					name: 'product',
					component: () => import('@/views/mp-assistant/month-saleinfo/product.vue'),
				}]
			}
		]
	},

]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router