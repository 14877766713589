<!-- layout -->
<template>
    <div class='h100'>
        <router-view />
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {

        }
    },
    computed: {},
    watch: {},
    methods: {

    },
    created() {

    },
    mounted() {
    },
}
</script>
<style scoped>
</style>