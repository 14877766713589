import { loadToken } from "@/api/login"

//act:业务token actExp:业务token有效时长  rft:置换token rftExp:置换token有效时长 loadTime:获取token时的时间戳
const token = 'token'
const userId = "userId"
const uid = "uid"
const platformCode = process.env.VUE_APP_PLATFORMCODE//平台码
const agentCode = process.env.VUE_APP_AGENTCODE//应用码

export function setToken(value) {
  value.loadTime = new Date().getTime()
  localStorage.setItem(token, JSON.stringify(value))
}

export function getToken(key = '') {
  if (localStorage.getItem(token)) {
    if (key) {
      return JSON.parse(localStorage.getItem(token))[key]
    } else {
      return JSON.parse(localStorage.getItem(token))
    }
  } else {
    return undefined
  }
}

export function getUserId() {
  return localStorage.getItem(userId)
};
export function setUserId(value) {
  localStorage.setItem(userId, value)
}
export function getUId() {
  return localStorage.getItem(uid)
};
export function setUId(value) {
  localStorage.setItem(uid, value)
}
export function removeAll() {
  localStorage.clear()
}
export function getPlatformCode() {
  return platformCode
}
export function getAgentCode() {
  return agentCode
}
export function getQyLoginUrl() {
  const CORPID = process.env.VUE_APP_APP_ID
  const REDIRECT_URI = encodeURI(process.env.VUE_APP_URL)
  //  snsapi_base：静默授权，可获取成员的基础信息（UserId与DeviceId）；
  //  snsapi_privateinfo：手动授权，可获取成员的详细信息，包含头像、二维码等敏感信息。
  const SCOPE = 'snsapi_privateinfo'
  const STATE = 'state'//重定向后携带的参数
  const AGENTID = agentCode
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${CORPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&state=${STATE}&agentid=${AGENTID}#wechat_redirect`
  return url
}
//判断accToken是否失效  默认提前0
export function isAccTokenValidity(duration = 0) {
  return new Date().getTime() - getToken('loadTime') > getToken('actExp') - duration
}
