import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		tokenInfo: null
	},
	getters: {
		tokenInfo: state => state.tokenInfo
	},
	mutations: {
		//set方法
		setTokenInfo(state, tokenInfo) {
			state.tokenInfo = tokenInfo
		}
	},
	actions: {},
	modules: {}
})