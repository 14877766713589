import request from '@/axios/request'
/**
 * 企业微信登录
 * @param {Object} data
 */
export function loginByQy(data) {
  return request({
    method: 'post',
    url: 'login/qylogin/agent',
    data: data,
    headers: { 'Content-type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 置换token
 */
export function exchangeToken(data) {
  return request({
    method: 'post',
    url: 'customer/token/tokenexchange',
    data: data,
    headers: { 'Content-type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 校验token
 */
export function checkToken(token){
	
}