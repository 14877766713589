import router from "./router";
import { getUserId } from '@/utils/auth'
router.beforeEach((to, from, next) => {
	if(to.path.indexOf('mp-assistant')>-1){
		console.log("开始学助手页面")
		next();
		return;
	}else{
		if (to.path != '/redirect' && to.query.code) {
		  //从企业微信重定向过来
		  next({ path: '/redirect', query: to.query })
		  return
		}
		console.log('getUserId()', getUserId())
		
		if (getUserId()) {
		  //有登陆权限
		  if (to.path == '/' || to.path == '/login') {
		    next({ path: '/base/home', query: to.query })
		    return
		  }
		  next()
		} else {
		  if (to.path != '/login' && to.path != '/redirect') {
		    next({ path: '/login' })
		  }
		}
		next()
	}
  
})