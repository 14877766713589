<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
	import {getToken, removeAll} from './utils/auth.js';
	export default{
		mounted() {
			this.checkUserStatus();
		},
		methods: {
			checkUserStatus(opts){
				const url = window.location.href;
				console.log("开始学助手小程序", Boolean(url.indexOf('mp-assistant')>-1))
				let res = getToken();
				// || Number(res.loadTime+res.actExp)<new Date().getTime()
				if(url.indexOf('mp-assistant')>-1){
					//开始学助手页面-微信小程序登录
				}else{
					//其他页面-企业微信登录
					if(!res){
						this.$router.replace({
							path: '/login'
						}).catch(() =>{})
					}
				}
			}
		}
	}
</script>
<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
@import url('style/common.css');
</style>
